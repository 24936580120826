<template>
  <p
    id="expired"
    :class="textColor"
    v-html="$t('instructions.expired', { product_name: productName, expiresAt: expiresAt })"
  ></p>
  <p
    id="contact-support"
    v-html="$t('form.contact_support_expired', { url: 'mailto:cs-jp@komodo.jp' })"
  ></p>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    productName: {
      type: String,
      default: '',
    },
    expiresAt: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
  },
});
</script>
