<template>
  <div class="container">
    <div class="container-top">
      <div class="container-left">
        <div class="code-redeem-box" :class="{ 'code-redeemed': steamKey }">
          <img class="publisher-logo" :src="merchantLogo" alt="CAPCOMロゴ" />
          <img class="product-logo" :src="ProductLogo" alt="モンスターハンターライズロゴ" />

          <div v-if="isExpired" class="product-container">
            <ExpiredForm
              :product-name="productName"
              :expires-at="expiresAt"
              :text-color="textColor"
            />
          </div>
          <div v-else class="product-container">
            <p id="instructions" :class="textColor" v-html="instructionText"></p>
            <p
              v-if="!steamKey"
              id="reminder"
              class="light-text"
              v-html="$t('instructions.reminder', { product_name: productName })"
            ></p>

            <KeyDelivery v-if="steamKey" :steam-key="steamKey"></KeyDelivery>
            <RedeemCodeForm v-else @redemption-success="showKey"></RedeemCodeForm>
          </div>
        </div>
        <p id="brand">{{ $t('brand') }}</p>
      </div>
      <div class="container-right"></div>
    </div>
    <div class="footer">
      <p id="copyright">©CAPCOM CO., LTD. ALL RIGHTS RESERVED.</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import RedeemCodeForm from '@/components/RedeemCodeForm.vue';
import ExpiredForm from '@/components/ExpiredForm.vue';
import KeyDelivery from '@/components/KeyDelivery.vue';
import MerchantLogo from '@images/MHRise/merchant_logo.svg';
import MerchantLogoDark from '@images/MHRise/merchant_logo_dark.svg';
import ProductLogo from '@images/MHRise/product_logo.png';

export default defineComponent({
  components: {
    RedeemCodeForm,
    ExpiredForm,
    KeyDelivery,
  },
  props: {
    expires: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      MerchantLogo: MerchantLogo,
      MerchantLogoDark: MerchantLogoDark,
      ProductLogo: ProductLogo,
      steamKey: '',
    };
  },
  computed: {
    instructionText() {
      return this.steamKey
        ? this.$t('instructions.enter_code', { expiresAt: this.expiresAt })
        : this.$t('instructions.redeem_gift_card_code');
    },
    textColor() {
      return this.steamKey ? 'light-text' : 'dark-text';
    },
    merchantLogo() {
      return this.steamKey ? MerchantLogoDark : MerchantLogo;
    },
    productName() {
      return this.$t('products.monster_hunter_rise.name');
    },
    isExpired() {
      return new Date().getTime() > this.expires.getTime();
    },
    expiresAt() {
      return this.$d(this.expires, 'long');
    },
  },
  methods: {
    showKey({ steamKey }) {
      this.steamKey = steamKey;
    },
  },
});
</script>

<style>
/* Background */

body {
  background-image: url('../../images/MHRise/background.png');
}

/* Logo */

.publisher-logo {
  margin: 2em 0;
  width: 150px;
}

.product-logo {
  width: 80%;
  margin: 1.5em 0;
}

/* Responsive */

@media (max-width: 900px) {
  .product-logo {
    margin-top: 0;
    margin-bottom: 1.5em;
  }
}

@media (max-width: 1300px) {
  .publisher-logo {
    margin: 0.8em 0;
  }

  .product-logo {
    margin: 0.8em 0;
  }
}

@media (max-width: 420px) {
  .publisher-logo {
    margin: 0.7em 0;
  }

  .product-logo {
    margin: 0.7em 0;
  }
}
</style>
