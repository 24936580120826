import axios from 'axios';

export default function (csrfToken, productLandingPath) {
  const axiosInstance = axios.create({
    timeout: 20000,
    headers: {
      'X-CSRF-TOKEN': csrfToken,
      Accept: 'application/json',
    },
    data: {
      product_landing_path: productLandingPath,
    },
  });

  return {
    post: function (data) {
      return axiosInstance.post('/redeem', {
        ...data,
        ...axiosInstance.defaults.data,
      });
    },
  };
}
