<template>
  <div id="key-box">
    <div id="steam-key">
      {{ steamKey }}
    </div>
    <div id="copy-steam-key" @click="copySteamKey">
      <span>{{ $t('copy_to_clipboard') }}</span>
      <img id="copy-icon" :src="CopyIcon" alt="复制图标" />
    </div>
  </div>
  <p
    id="steam-help"
    v-html="$t('instructions.redeem_steam_key', { url: 'https://www.capcom.co.jp/game/steamkey/' })"
  ></p>
</template>

<script>
import { defineComponent } from 'vue';
import CopyIcon from '@images/MHRise/copy.svg';

export default defineComponent({
  props: {
    steamKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      CopyIcon: CopyIcon,
    };
  },
  methods: {
    copySteamKey() {
      navigator.clipboard.writeText(this.steamKey);
    },
  },
});
</script>

<style>
#key-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: #1c1814;
  border-radius: 14px;
  margin: 0 1em;
  width: 500px;
  height: 220px;
  border: 2px solid;
  box-shadow: 2px 2px 4px -2px gray;
  text-align: center;
}

#steam-key {
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  flex-grow: 0.8;
}

#copy-steam-key {
  color: #9688ee;
  align-self: auto;
  display: flex;
  cursor: pointer;
}

#copy-icon {
  margin-left: 10px;
}

#steam-help {
  font-size: 0.8em;
  font-weight: bold;
  line-height: 1.4rem;
  margin: 1.5rem 0;
  text-align: center;
  width: 80%;
  color: #ffffff;
}

#steam-redeem-help-link {
  color: #9688ee;
}

@media (max-width: 900px) {
  #key-box {
    width: 300px;
  }

  #steam-help {
    font-size: 0.75em;
    line-height: unset;
  }
}
</style>
