import ApiClient from '@/services/ApiClient';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import ja from '../../../config/locales/ja.yml';

// Vue-18n datetime configuration
const datetimeFormats = {
  en: {
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  ja: {
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
  },
};

const i18n = createI18n({
  locale: 'ja',
  messages: {
    ja: ja.ja,
  },
  datetimeFormats,
});

export default (component, productLandingPath) => {
  document.addEventListener('DOMContentLoaded', () => {
    // create app
    const productData = document.getElementById('product-data');
    const dateExpires = JSON.parse(productData.getAttribute('data-expires'));
    const app = createApp(component, { expires: new Date(dateExpires) });

    // create api
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const api = ApiClient(csrfToken, productLandingPath);

    // mount
    app.config.globalProperties.$api = api;
    app.use(i18n);
    app.mount('#app');
  });
};
