<template>
  <form action="/redeem" @submit.prevent="onSubmit">
    <div class="input-group">
      <label for="posa-code-input">{{ $t('form.label') }}</label>
      <input
        id="posa-code-input"
        v-model="code"
        type="text"
        :placeholder="$t('form.placeholder')"
        required
        minlength="10"
        maxlength="20"
      />
      <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
    </div>
    <input
      type="submit"
      :value="$t('form.submit')"
      :class="{ disabled: isSubmitting }"
      :disabled="isSubmitting"
    />
  </form>
  <p
    id="contact-support"
    v-html="
      $t('form.contact_support', {
        urlSupport: urlSupport,
        urlHelp: urlHelp,
      })
    "
  ></p>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    urlHelp: {
      type: String,
      default: 'https://www.capcom-games.com/ja-jp/steamkey/',
    },
    urlSupport: {
      type: String,
      default: 'mailto:cs-jp@komodo.jp',
    },
  },
  emits: ['redemptionSuccess'],
  data() {
    return {
      code: '',
      errorMessage: '',
      isSubmitting: false,
    };
  },
  methods: {
    onSubmit() {
      this.isSubmitting = true;
      this.$api
        .post({ code: this.code })
        .then((res) => {
          if (res.status === 200) {
            this.emitRedemptionSuccess(res.data);
            this.isSubmitting = false;
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            this.errorMessage = err.response.data.message;
          } else {
            this.errorMessage = this.$t('errors.redemption_server_error');
          }

          this.isSubmitting = false;
        });
    },
    emitRedemptionSuccess(data) {
      this.errorMessage = '';
      this.$emit('redemptionSuccess', { steamKey: data.steam_key, expiresAt: data.expires_at });
    },
  },
});
</script>

<style>
form {
  width: 90%;
}

.input-group {
  position: relative;
}

.input-group > label {
  position: absolute;
  top: 5%;
  left: 22px;
  padding: 0 0.3em;
  font-size: 0.6em;
  background: #2c4156;
  border-radius: 2px;
  color: white;
}

.input-group > input {
  font-size: 1.2em;
  padding: 1em;
  border-radius: 0.5em;
  width: 100%;
  margin: 0.5em 0;
  height: 4.5rem;
  border: 2.72px solid #2c4156;
  background: none;
}

.input-group > input:focus {
  outline: none;
}

.error {
  color: red;
}

input[type='submit'] {
  transition: all 0.1s;
  cursor: pointer;
  width: 100%;
  height: 4.5rem;
  margin: 0.5em 0 0 0;
  background: #192a59;
  border-radius: 0.5em;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
}

input[type='submit']:hover {
  background: #1b4794;
}

input[type='submit'].disabled {
  cursor: wait;
  background: #506cb9;
}

#contact-support {
  width: 80%;
  margin: 1.5rem 0;
  font-weight: bold;
  font-size: 0.8em;
  line-height: 1.4rem;
  text-align: center;
  color: #2c4156;
}
</style>
