export default {
  "ja": {
    "errors": {
      "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。現在作業中ですので、後ほどもう一度お試しください。"])},
      "missing_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。現在作業中ですので、後ほどもう一度お試しください。"])},
      "product_id_does_not_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。現在作業中ですので、後ほどもう一度お試しください。"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードでは引換えができません。入力間違いがないか、ご確認ください。"])},
      "amount_too_small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。現在作業中ですので、後ほどもう一度お試しください。"])},
      "amount_not_an_integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。現在作業中ですので、後ほどもう一度お試しください。"])},
      "insufficient_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。現在作業中ですので、後ほどもう一度お試しください。"])},
      "currency_does_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。現在作業中ですので、後ほどもう一度お試しください。"])},
      "card_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードは有効期限が切れている為、利用できません。"])},
      "card_fully_redeemed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードは既に引き換え済みです。"])},
      "card_deactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードは有効ではありません。"])},
      "card_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードは現在、保留になっています。"])},
      "card_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードは停止済みです。"])},
      "card_stolen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードは盗難品になります。"])},
      "card_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードは紛失したとマークされています。"])},
      "card_damaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードは破損しています。"])},
      "card_unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。現在作業中ですので、後ほどもう一度お試しください。"])},
      "invalid_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。現在作業中ですので、後ほどもう一度お試しください。"])},
      "posa_code_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コードを入力してください。"])},
      "invalid_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードでは引換えができません。入力間違いがないか、ご確認ください。"])},
      "invalid_posa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードでは引換えができません。入力間違いがないか、ご確認ください。"])},
      "redemption_server_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。現在作業中ですので、後ほどもう一度お試しください。"])},
      "upc_code_does_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご入力頂いたコードは当サイトで引き換えを行う事が出来ません。カードまたはメールに記載されているコード引換サイトのURLをご確認ください。"])},
      "product_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したコードは有効期限が切れている為、利用できません。"])}
    },
    "products": {
      "monster_hunter_rise": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モンスターハンターライズ"])}
      },
      "monster_hunter_rise_sunbreak": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モンスターハンターライズ：サンブレイク"])}
      },
      "capcom": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAPCOM コード引換サイト"])}
      },
      "p5r": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペルソナ５ ザ・ロイヤル コード"])}
      },
      "sonicfrontiers": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソニックフロンティア コード"])}
      },
      "inshin_kiwami": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["龍が如く 維新！ 極 コード"])}
      },
      "sega": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEGA コード引換サイト"])}
      },
      "atlus": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATLUS コード引換サイト"])}
      },
      "komodo": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOMODO コード引換サイト"])}
      }
    },
    "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピーする"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Komodo"])},
    "instructions": {
      "enter_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["下記のSteamキーをSteamクライアントで<br/><span style=\"color:red\">", _interpolate(_named("expiresAt")), "まで</span>に<br/>有効化してください。<br/>Steamクライアントでの有効化の詳細は<a id=\"steam-redeem-help-link\" href=\"https://www.capcom-games.com/ja-jp/steamkey/\" target=\"_blank\">こちら</a>。"])},
      "redeem_gift_card_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下の入力欄に引換コード<br/>を入力してください"])},
      "redeem_steam_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コード引換ページに再度引換コードを入力することで、同じSteamキーを再表示できます。"])},
      "reminder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["本サイトは、パソコン(Steam)版「", _interpolate(_named("product_name")), "」のコード引換サイトです。他機種版ゲームとの引換えはできません。<br/><br/>他機種版ゲームと誤って購入された場合は、コードの引換をせず、ご購入時のレシートおよび購入カードをご用意のうえ、購入された店舗へご相談ください。\n"])},
      "reminder_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本サイトは、パソコン(Steam)版カプコンタイトルのコード引換サイトです。他機種版ゲームとの引換えはできません。<br/><br/>他機種版ゲームと誤って購入された場合は、コードの引換をせず、ご購入を証明するもの（レシート・購入完了メール等）や、引換コード受け取り時のメール等をご用意の上、購入された店舗へご相談ください。\n"])},
      "expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["本サイトは、パソコン(Steam)版「", _interpolate(_named("product_name")), "」のコード引換サイトです。<br/><br/>", _interpolate(_named("expiresAt")), "をもちまして、Steamキーへの引換えは終了しました。\n"])}
    },
    "form": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引換コード"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コードを入力"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steamキーに引換える"])},
      "contact_support": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["一度Steamキーに引換えた後でも、再度引換コードを入力することで、同じSteamキーを再表示できます。ご不明点がありましたら、<a id=\"customer-support-link\" href=\"", _interpolate(_named("urlSupport")), "\">サポート</a>までご連絡ください。Steamのご利用方法やSteamキーの入力手順は<a id=\"steam-redeem-help-link\" href=\"", _interpolate(_named("urlHelp")), "\" target=\"_blank\">こちら</a>をご確認ください。"])},
      "contact_support_expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ご不明点がありましたら、<a id=\"customer-support-link\" href=\"", _interpolate(_named("url")), "\">サポート</a>までご連絡ください。"])}
    }
  }
}